import User, { SystemUserCredentials } from "../../models/User";
import { FieldValidator } from "../core/errorable_field/validators";
import { apis } from "../../backend";
import { Organization } from "../../models/Organization";
import { getUserId } from "../../utils/user";
import { Feature } from "../../models/Feature";
import { detectOS } from "detect-browser";
import { text } from "../../utils/i18n";

const os = detectOS(navigator?.userAgent);

export type UserId = string;

type UserFilter = {
  organizationId?: string;
  anyOrganization?: boolean;
  suspended?: boolean;
}

/**
 * Fetches users of either:
 * - the current organization
 * - a specific organization
 * - any organization
 */
export async function listUsers(filter?: UserFilter): Promise<User[]> {
  const users = await apis.users.get<User[]>('/users-v1', filter);

  return users.map(user => new User(user));
}

/**
 * Gets a specific user.
 */
export async function getUser(userId: string): Promise<User> {
  const user = await apis.users.get<User>(`/users-v1/${userId}`);

  return new User(user);
}

/**
 * Fetches current organization.
 */
export async function getOrganization(): Promise<Organization> {
  const organization = await apis.users.get<Organization>('/organizations-v1/me');

  return new Organization(organization);
}

/**
 * Creates a new user.
 */
export async function createUser(user: User): Promise<{ userId: string, systemUserCredentials: SystemUserCredentials }> {
  const { userId, systemUserCredentials } = await apis.users.post<{ userId: string, systemUserCredentials: SystemUserCredentials }>(
    '/users-v1', { ...user, email: user.email || undefined });

  return { userId, systemUserCredentials }
}

/**
 * Updates an existing user.
 */
export async function updateUser(update: Partial<User>, userId?: string) {
  const currentUserId = getUserId();
  userId = userId || update.id || currentUserId;

  if (userId === currentUserId) {
    await apis.users.put(`/users-v1/me`, update);
  } else {
    await apis.users.put(`/users-v1/${userId}`, update);
  }
}

/**
 * Sends an activation email to a user.
 */
export async function sendUserActivation(userId: string) {
  await apis.users.post(`/users-v1/${userId}/activation`);
}

export const validateUsername: FieldValidator = (username: string) => {
  return getPasswordValidationMessage(username) ?? true;
}


export function getUsernameValidationMessage(username: string): string | null {
  if (!username?.length || username?.length < 5) {
    return text`Ange minst ${5} tecken`;
  }

  const validChars = /^[\dA-ZÅÄÖ\-\._]+$/i;

  if (!validChars.test(username)) {
    return text`Ange tillåtna tecken`;
  }

  if (/^[-\._]/.test(username)) {
    return text`Kan ej börja med specialtecken`;
  }

  if (/[\-\._]$/.test(username)) {
    return text`Kan ej sluta på specialtecken`;
  }

  const adjacentSpecialChars = /[\-\._]{2,}/;
  if (adjacentSpecialChars.test(username)) {
    return text`Kan ej upprepa specialtecken`;
  }

  if (username.toLowerCase() == 'salesys') {
    return text`Reserverat`;
  }

  return null;
}

export function getPasswordValidationMessage(password: string): string | null {
  if (password?.length < 12) {
    return text`Ange minst {12} tecken`;
  }

  if (!/[A-Z]/.test(password) || !/[a-z]/.test(password)) {
    return text`Ange stor och liten bokstav`;
  }

  if (!/[0-9]/.test(password)) {
    return text`Ange ett nummer`;
  }

  return null;
}

/**
 * Deletes a user.
 */
export async function deleteUser(userId: string) {
  await apis.users.del(`/users-v1/${userId}`);
}

/**
 * Enables the given feature for the current user.
 * @param organizationId Option ID of an organization to enable the feature for.
 */
export async function enableFeature(feature: Feature, organizationId?: string) {
  if (organizationId) {  
    await apis.users.post(`/features-v1/${organizationId}/${feature}`);
  } else {
    await apis.users.post(`/features-v1/${feature}`);
  }
}

/**
 * Disables the given feature for the current user.
 * @param organizationId Option ID of an organization to disable the feature for.
 */
export async function disableFeature(feature: Feature, organizationId?: string) {
  if (organizationId) {  
    await apis.users.del(`/features-v1/${organizationId}/${feature}`);
  } else {
    await apis.users.del(`/features-v1/${feature}`); 
  }
}