import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownProps, DropdownItem, DropdownGroup } from '../Dropdown/Dropdown';
import cls from 'classnames';
import FontAwesomeIcon from '../fa_icon';
import { CurrentThemeContext } from '../../ThemeContextProvider/CurrentThemeContextProvider';

export type SelectOption = DropdownItem & { value?: any; }

export type SelectGroup = {
  key: string;
  title: string;
  options: (SelectOption | null)[];
  isHidden?: boolean;
}

export interface SelectProps extends Pick<DropdownProps, 'queryPlaceholder' | 'queryInputPlaceholder' | 'autoCompact' | 'autoCompactQuery' | 'singularId'> {
  options?: (SelectOption | null)[];
  groups?: (SelectGroup | null)[];

  /**
   * Whether to style this select similar to that of a text input.
   */
  inputLike?: boolean;

  dropdownTitle?: string;
  value?: any;

  /**
   * Rendered in place of the static active value.
   */
  activeValueComponent?: React.ReactNode;

  readOnly?: boolean;
  className?: string;
  innerClassName?: string;
  innerStyle?: CSSProperties;
  placeholder?: string;

  /**
   * Force the placeholder to show regardless of an active value.
   */
  forcePlaceholder?: boolean;
  dropdownAlign?: 'left' | 'right';
  noChevron?: boolean;
  hideDropdownOnBlur?: boolean;

  /**
   * May return true to close the select.
   */
  onChange?: (value: any, itemIndex: number, item: SelectOption | string) => void | boolean;

  id?: string;
  testId?: string;

  /**
   * Whether to always render the selected item's icon.
   */
  persistIcon?: boolean;

  style?: React.CSSProperties;

  chevronPosition?: 'before' | 'after';

  onClick?: (event: React.MouseEvent) => void;

  /**
   * Whether to adjust the width based on the selected content.
   */
  collapse?: boolean;

  /**
   * Whether to automatically open the dropdown.
   */
  autoFocus?: boolean;
}

/**
 * Select-like component with a dropdown.
 */
export const Select: React.FC<SelectProps> = (props) => {
  if (props.options && props.groups) {
    throw new Error("Cannot provide both options and groups.");
  }

  if (!props.options && !props.groups) {
    throw new Error("Provide either options or groups.");
  }

  //  props.options/props.groups with null-ish options removed.
  const safePropsOptions = props.options?.filter(option => option);
  const safePropsGroups = props.groups?.filter(group => Boolean(group))
    .map(group => ({ ...group, options: group.options.filter(option => option) }));

  const groups = safePropsGroups || [{ title: null, options: safePropsOptions, key: Math.random().toString() }];
  const allOptions: SelectOption[] = groups.reduce((p, group) => [...p, ...group.options], []);

  //  Prevents onBlur from closing the dropdown once an option is clicked.
  const shouldHandleBlur = useRef(false);
  const handleBlurTimeout = useRef(null);
  const currentThemeContext = useContext(CurrentThemeContext);

  let [dropdownOpen, setDropdownOpen] = useState(false);

  function showDropdown() {
    shouldHandleBlur.current = true;
    setDropdownOpen(true);
  }

  useEffect(() => {
    if (props.autoFocus) {
      showDropdown();
    }
  }, [props.autoFocus]);

  const { readOnly, value } = props

  const activeValueItem = allOptions.find(e => e.value === value);

  let activeValueLabel: React.ReactNode = (
    activeValueItem ? activeValueItem.text : value
  );

  const checkedItems = allOptions.filter(item => item.checked === true);
  if (checkedItems.length > 1) {
    activeValueLabel = `${checkedItems.length} valda`;
  } else if (checkedItems.length == 1) {
    activeValueLabel = checkedItems[0].text;
  }

  if (!activeValueLabel && props.placeholder || props.forcePlaceholder) {
    activeValueLabel = (
      <span className="placeholder">{props.placeholder}</span>
    );
  }

  let { activeValueComponent, persistIcon } = props;
  if (!activeValueComponent || typeof activeValueComponent === 'string') {
    activeValueComponent = (
      <SelectedValue>
        {persistIcon && typeof activeValueItem?.icon === 'string' ? (<FontAwesomeIcon name={activeValueItem.icon} />) : null}
        {activeValueComponent || activeValueLabel}
      </SelectedValue>
    );
  }

  const chevron = (
    !readOnly && !props.noChevron ? (
      <span className="expand-icon">
        <FontAwesomeIcon name="chevron-down" />
      </span>
    ) : null
  );

  return (
    <div
      className={cls(["select-like-container", props.className])}
      style={{ width: props.inputLike ? '100%' : null, ...props.style }}
      onFocus={props.hideDropdownOnBlur ? () => {
        clearTimeout(handleBlurTimeout.current);
        showDropdown();
      } : null}
      onBlur={props.hideDropdownOnBlur ? () => {
        handleBlurTimeout.current = setTimeout(() => {
          if (shouldHandleBlur.current) {
            setDropdownOpen(false);
          }
        }, 16);
      } : null}>
      <div
        id={props.id}
        className={cls([
          "select-like",
          props.inputLike ? "input-like" : null,
          dropdownOpen ? "focus" : null,
          props.collapse ? "collapse" : null,
          props.innerClassName
        ])}
        data-input-style={currentThemeContext?.theme?.inputStyle}
        style={props.innerStyle}
        role="button"
        onClick={event => {
          props.onClick?.(event);

          if (props.hideDropdownOnBlur) {
            return;
          }

          if (!dropdownOpen) {
            showDropdown();
          } else {
            setDropdownOpen(false);
          }
        }}
        data-testId={props.testId}>
        {props.chevronPosition == 'before' ? chevron : null}
        {activeValueComponent}
        {props.chevronPosition == 'after' ? chevron : null}
      </div>
      {
        !readOnly ? (
          <Dropdown
            open={dropdownOpen}
            onClose={() => setDropdownOpen(false)}
            items={safePropsOptions ? safePropsOptions.map(option => ({
              ...option,
              object: option.value
            })) : null}
            groups={safePropsGroups ? safePropsGroups.map(group => ({
              key: group.key,
              title: group.title,
              isHidden: group.isHidden,
              items: group.options.map(option => ({
                ...option,
                object: option.value
              }))
            })) : null}
            horizontalPosition={props.dropdownAlign}
            onMouseEnter={() => shouldHandleBlur.current = false}
            onMouseLeave={() => shouldHandleBlur.current = true}
            onItemClicked={({ object }, group, groupIndex) => {
              shouldHandleBlur.current = false;

              const optionIndex = group.items.findIndex(option => option.object === object);
              const value = object;
              let res = props.onChange?.(value, optionIndex, groups[groupIndex].options[optionIndex]);
              if (res === true || res === undefined) {
                setDropdownOpen(false)
              }
            }}
            maxWidth={false}
            title={props.dropdownTitle}
            queryInputPlaceholder={props.queryInputPlaceholder}
            queryPlaceholder={props.queryPlaceholder}
            autoCompact={props.autoCompact}
            autoCompactQuery={props.autoCompactQuery}
            singularId={props.singularId} />
        ) : null
      }
    </div>
  )
}

Select.defaultProps = {
  dropdownAlign: 'right',
  chevronPosition: 'after'
};

/**
 * Use this to render a default selected value…
 */
export const SelectedValue: React.FC = ({ children }) => (
  <p className="selected item">
    {children}
  </p>
);

export function toDropdownGroup(selectGroup: SelectGroup): DropdownGroup {
  return {
    key: selectGroup.key,
    title: selectGroup.title,
    items: selectGroup.options
  };
}