import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { Store, useStore } from 'react-stores';
import User, { UserType } from '../../models/User';
import { Users } from '../../models/Users';
import { sleep } from '../../utils';
import { setTimezone } from '../../utils/date';
import { getUserId } from '../../utils/user';
import { listUsers as listUsersService, getOrganization } from "./service";
import "moment-timezone";
import { Organization } from '../../models/Organization';
import { Feature } from '../../models/Feature';
import { disableFeature as disableFeatureService, enableFeature as enableFeatureService } from './service';
import { logout } from '../settings/AccountSettingsSection/service';
import { AppType, appType } from '../../AppType';

class UserState {
  users = new Users([]);
  isRefreshing = true;

  organization: Organization = null;

  /**
   * Will cause a re-fetch if this value changes.
   */
  _fetchTrigger?: number;
  refresh: () => void;
}

export const globalUserStore = new Store<UserState>({
  ...new UserState(),
  refresh: function () {
    globalUserStore.setState({
      _fetchTrigger: Date.now()
    })
  }
});

type Props = {
  fetchAtleastOnce?: boolean;
}

/**
 * Fetches users of the current user's organization, in the background.
 */
export const UserDispatcher: React.FC<Props> = ({ fetchAtleastOnce = true }) => {

  const isMounted = useRef(false);
  const userStore = useStore(globalUserStore);
  const { _fetchTrigger } = userStore;

  async function listCurrentUsers() {
    globalUserStore.setState({ isRefreshing: true });

    while (true) {
      try {
        var [users, organization] = await Promise.all([
          listUsersService(),
          getOrganization()
        ]);
        break;
      } catch (e) {
        console.error(e);
        await sleep(5000);
      }
    }

    const usersContainer = new Users(users);

    moment.tz.setDefault(usersContainer.current.timezone);
    setTimezone(usersContainer.current.timezone);

    globalUserStore.setState({
      users: usersContainer,
      organization,
      isRefreshing: false
    });
  }

  useEffect(() => {
    if (fetchAtleastOnce || isMounted.current) {
      listCurrentUsers();
    }

    isMounted.current = true;
  }, [_fetchTrigger]);

  return null;
}

/**
 * Utilizes the `useStore` hook to obtain the current user.
 * Thus this must invoked in a function component during render.
 * @returns 
 */
export function useCurrentUser(): User | null {
  const userStore = useStore(globalUserStore);
  const userId = getUserId();
  const currentUser = userStore.users?.current || null;

  if (appType === AppType.DashboardLink) {
    //  Since we're not logged in, fake the current user.
    return new User({
      type: UserType.Standard
    });
  }

  if (!userId) {
    //  We end up here if we logged out from another tab.
    //  We can use `users.current` since it's the last known current user, until we do a hard navigation to the login view.
    console.log("User ID cookie was removed, logging out");
    logout();
  } else if (currentUser && currentUser.id !== userId) {
    //  We end up here if we log in as support while having an already logged-in tab with the previous user.
    console.log("User ID cookie changed, reloading");
    window.location.reload();
  }

  return currentUser;
}

/**
 * Hook for getting the user's organization.
 */
export const useOrganization = () => useStore(globalUserStore).organization;

/**
 * Use this to obtain the current user without reacting to changes.
 * @returns The current user.
 */
export function getCurrentUser(): User | null {
  return globalUserStore.state.users.current;
}

/**
 * Utilizes the `useStore` hook to obtain all users.
 * Thus this must invoked in a function component during render.
 * @returns All users.
 */
export function useAllUsers(): User[] {
  return useStore(globalUserStore).users.all;
}

/**
 * Utilizes the `useStore` hook to obtain all active users.
 * Thus this must invoked in a function component during render.
 * @returns All users.
 */
export function useActiveUsers(): User[] {
  return useStore(globalUserStore).users.activeUsers;
}

/**
 * Enables a feature and updates store.
 */
export async function enableFeature(feature: Feature) {
  await enableFeatureService(feature);

  globalUserStore.setState({
    organization: globalUserStore.state.organization.withFeature(feature)
  });
}

/**
 * Disables a feature and updates store.
 */
export async function disableFeature(feature: Feature) {
  await disableFeatureService(feature);

  globalUserStore.setState({
    organization: globalUserStore.state.organization.withoutFeature(feature)
  });
}