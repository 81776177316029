import React, { ReactChild, useRef } from 'react';
import cls from 'classnames';
import { TextInput, formatBlurredValue, TextInputProps } from './TextInput';
import './styles.scss';
import { isDateValid } from '../../../utils';
import { DateSelect } from '../DateSelect/DateSelect';
import { PopoverProps } from '../popover';
import { OrderFieldDateValidator } from '../../../models/OrderFieldValidation';
import { getDateValidationBounds } from '../DateSelect/DateList';

type Style = React.CSSProperties;

export type FieldProps = TextInputProps & {
  label: string | React.ReactNode;

  /**
   * Similar to RibbonProps.immediate, this element will be rendered next to the label.
   */
  immediate?: React.ReactNode;
  fullWidth?: boolean;
  containerStyle?: Style;
  containerClassName?: string;
  inputContainerStyle?: Style;
  labelClassName?: string;

  /**
   * Whether to increase label focus, useful for nested lists.
   */
  focusLabel?: boolean;
  component?: JSX.Element;
  type?: string | 'date';
  labelAlignment?: 'column' | 'row';
  onDateValueChange?: (date: Date) => void;
  dateValue?: string | Date;
  /**
   * For date select, whether to include time.
   */
  dateTime?: boolean;

  /**
   * Whether the render value as static and ineditable.
   * 
   * Unlike `readOnly`, setting this flag does not render a text input.
   */
  static?: boolean;

  /**
   * Rendered below the field.
   */
  sub?: ReactChild;

  /**
   * Formats the value, visible when blurred.
   * 
   * If `true`, and `allow` is `numeric`, then the numeric value is formatted.
   */
  formatBlurredValue?: true | ((value: string | string[] | number) => string);

  /**
   * Whether to format the value, if blurred, and if the value evaluates to false.
   */
  formatBlurredEmptyValue?: boolean;

  /**
   * Whether to use a custom date picker, or the standard browser one.
   * 
   * @default true
   */
  useCustomDatePicker?: boolean;
  validation?: {
    date?: OrderFieldDateValidator;
  };
} & Partial<Pick<PopoverProps, 'popoverId' | 'parentPopoverId'>>;

/**
 * @returns Props to spread over the input.
 */
function getRestProps(props: FieldProps) {
  let restProps = { ...props }
  delete restProps.component
  delete restProps.label;
  delete restProps.fullWidth;
  delete restProps.containerStyle;
  delete restProps.containerClassName;
  delete restProps.inputContainerStyle;
  delete restProps.onDateValueChange;
  delete restProps.nodeRef;
  delete restProps.labelAlignment;
  delete restProps.static;
  restProps.className = cls({
    [restProps.className]: true,  //  TODO: fix class="undefined"
    "full-w": props.fullWidth
  })
  return restProps
}

const defaultMaxLength = 500;

/**
 * Renders a label and either a static or editable value.
 */
export function Field({
  id,
  labelClassName,
  immediate,
  popoverId,
  parentPopoverId,
  focusLabel,
  dateTime = false,
  useCustomDatePicker = true,
  validation,
  ...props
}: FieldProps) {
  let CustomComponent = props.component;

  //  Auto-generated ID for htmlFor support
  const autoId = useRef<string>('f-' + Date.now() + Math.floor(100000 * Math.random()));

  if (props.type === 'date' && useCustomDatePicker) {
    let value = props.dateValue || props.value as string;
    if (typeof value == "string") {
      var valueAsDate = new Date(value);
      if (!isDateValid(valueAsDate)) {
        valueAsDate = null;
      }
    } else if (isDateValid(value as any)) {
      var valueAsDate = (value as any) as Date;
    } else {
      var valueAsDate = null as Date;
    }

    if (!popoverId) {
      console.warn("You are rendering a field with type \"date\", without providing a popoverId, which might result in a bug!");
    }

    CustomComponent = (
      <DateSelect
        onDateValueChange={date => props.onDateValueChange?.(date)}
        placeholder={props.placeholder}
        value={valueAsDate}
        readOnly={props.readOnly}
        disabled={props.disabled}
        popoverId={popoverId}
        parentPopoverId={parentPopoverId}
        usePortal
        time={dateTime}
        validation={validation?.date}
      />
    );
  }

  if (!id) {
    id = autoId.current;
  }

  const dateBounds = props.type === "date" && validation?.date &&
    !useCustomDatePicker ? getDateValidationBounds(validation.date) : null;

  const defaultMin = dateBounds?.after?.format("YYYY-MM-DD");
  const defaultMax = dateBounds?.before?.format("YYYY-MM-DD");

  return (
    <div className={cls([
      "field",
      props.containerClassName,
      props.labelAlignment ? `label-align-${props.labelAlignment}` : null,
      props.fullWidth ? 'full-w' : null
    ])}
      data-field-id={autoId.current} // Allows CSS modules to select a field.
      data-skeleton
      style={props.containerStyle}>
      <div>
        {
          props.label ? (
            <div className={cls(["label-container", labelClassName])}>
              <label className={cls(["label-content", focusLabel ? "focus-label" : null])} htmlFor={id}>{props.label}</label>
              {immediate}
            </div>
          ) : null
        }
        {
          CustomComponent ? CustomComponent : (
            props.static ? (
              <p className={cls([props.className, "static-value"])}>
                {formatBlurredValue(getRestProps(props))}
              </p>
            ) : (
              <TextInput
                type={props.type || "text"}
                id={id}
                {...getRestProps(props)}
                min={props.min === undefined ? defaultMin : props.min}
                max={props.max === undefined ? defaultMax : props.max}
                maxLength={props.maxLength === undefined ? defaultMaxLength : props.maxLength}
                nodeRef={props.nodeRef}
                containerStyle={props.inputContainerStyle} />
            )
          )
        }
      </div>
      {props.sub ? (<p className="sub">{props.sub}</p>) : null}
      {
        props.children
      }
    </div>
  )
}