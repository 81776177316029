import Cookie from "js-cookie";

export function askRemoveSupportLock() {
  if (window.confirm("Vill du inaktivera kundserviceläget och kunna göra ändringar?")) {
    Cookie.remove("s2_supportClientUserId");
    Cookie.remove("s2_supportAgentUserId");

    return true;
  }

  return false;
}