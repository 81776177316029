import React from 'react';
import cls from 'classnames';
import './styles.scss';
import { SwitchProps } from '../Switch/Switch';

export interface CheckboxProps extends SwitchProps {
  type?: 'checkbox' | 'radio';
  required?: boolean;
}

/**
 * A smaller variant of the switch component.
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  checked, disabled, onChange, required, type = 'checkbox'
}) => {
  return (
    <input
      type="checkbox"
      className={cls({
        "checkbox": true,
        "disabled": disabled,
        ["type-" + type]: true
      })}
      required={required}
      checked={checked}
      disabled={disabled}
      onChange={event => onChange?.(event.currentTarget.checked, event)} />
  )
}