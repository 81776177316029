import React, { CSSProperties, useEffect, useRef } from 'react';
import moment from 'moment';
import { TextInput } from '../../../../core/field/TextInput';
import styles from "./TimeSelect.module.scss";
import { useImmediateEffect } from '../../../../hooks';
import { insertEventListener, removeIndexedEventListener } from '../../../../../utils/events';

type Props = {
  value?: Date;
  onChange?: (newValue: Date) => void;
  onBlurChange?: (newValue: Date) => void;

  minuteTestId?: string;
  hourTestId?: string;
  readOnly?: boolean;
  inputStyle?: CSSProperties;
}

/**
 * Allows for editing hours and minutes.
 */
export const TimeInput: React.FC<Props> = ({
  hourTestId, inputStyle, minuteTestId, onChange, onBlurChange, readOnly, value
}) => {
  value = value ?? new Date();

  //  Value to be passed to onBlurChange - temporary value while focusing.
  const onBlurValue = useRef<Date>(null);
  const isFocus = useRef<boolean[]>([false, false]);
  //  Becomes false once onBlur finishes.
  const wasFocused = useRef<boolean[]>([false, false]);
  //  setTimeout handler for various workarounds.
  const onBlurChangeId = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const path = event.composedPath();
      if (path.includes(containerRef.current)) {
        //  Clicked on self.
        return;
      }

      const focused = containerRef.current?.querySelector('input:focus') as HTMLInputElement;
      if (!focused && !onBlurChangeId.current) {
        //  No focused input.
        //  Not waiting for onBlur.
        return;
      }

      if (path.find(element =>
        element instanceof HTMLElement && element.getAttribute('data-time-input-prevents-click') === "false")) {
        //  Should not prevent TimeSelect options from being clicked.
        return;
      }

      console.warn("TimeInput prevented %s event due to awaiting blur handler", event.type);

      event.preventDefault();
      event.stopPropagation();

      focused.blur();
    };

    insertEventListener('click', listener, 0);

    return () => { removeIndexedEventListener('click', listener); }
  }, []);

  useImmediateEffect([value.getMinutes(), value.getHours()].join(), () => {
    if (!isFocus.current.includes(true)) {
      //  Happens when clicked on followed by clicking on a dropdown option.
      onBlurValue.current = null;
    }
  });

  function onBlur(which: 'hour' | 'minute') {
    clearTimeout(onBlurChangeId.current);

    onBlurChangeId.current = setTimeout(() => {
      onBlurChangeId.current = null;

      if (which === 'hour') {
        wasFocused.current[0] = false;

      } else if (which === 'minute') {
        wasFocused.current[1] = false;
      }

      if (wasFocused.current.includes(true) || !onBlurValue.current) {
        return; //  Checking/setting wasFocused fixes onBlurValue being reset when switching inputs with the tab key.
      }

      const value = new Date(onBlurValue.current);

      onBlurValue.current = null;
      onBlurChange?.(value);
    }, 50); // Allow TimeSelect to cancel onBlurChange when clicked on an option.
  }

  function onFocus() {
    if (!wasFocused.current.includes(true)) {
      onBlurValue.current = new Date(value);
    }
  }

  const activeValue = onBlurValue.current ?? value;

  return (
    <div
      className={styles.container}
      ref={element => containerRef.current = element}>
      <div>
        <Input
          value={activeValue?.getHours()}
          onChange={hours => {
            if (typeof hours === 'number') {
              onBlurValue.current?.setHours(hours);

              onChange?.(
                moment(onBlurValue.current ?? value)
                  .hour(hours)
                  .toDate()
              );
            }
          }}
          onBlur={() => {
            isFocus.current[0] = false;
            onBlur('hour');
          }}
          onFocus={() => {
            onFocus();
            isFocus.current[0] = true;
            wasFocused.current[0] = true;
          }}
          min={0}
          max={23}
          pad
          testId={hourTestId}
          readOnly={readOnly}
          style={inputStyle}
        />
      </div>
      <div>
        <p className={styles.separator}>:</p>
      </div>
      <div>
        <Input
          value={activeValue?.getMinutes()}
          onChange={minutes => {
            if (typeof minutes === 'number') {
              onBlurValue.current?.setMinutes(minutes);

              onChange?.(
                moment(onBlurValue.current ?? value)
                  .minute(minutes)
                  .toDate()
              );
            }
          }}
          onFocus={() => {
            onFocus();
            isFocus.current[1] = true;
            wasFocused.current[1] = true;
          }}
          onBlur={() => {
            isFocus.current[1] = false;
            onBlur('minute');
          }}
          min={0}
          max={59}
          pad
          testId={minuteTestId}
          readOnly={readOnly}
          style={inputStyle}
        />
      </div>
    </div>
  )
}

type InputProps = {
  style?: CSSProperties;
  value: number;
  onChange: (newValue: number) => void;
  onBlur?: (value: number) => void;
  onFocus?: () => void;
  max?: number;
  min?: number;
  pad?: boolean;
  testId?: string;
  readOnly?: boolean;
};

const Input: React.FC<InputProps> = (props) => {
  return (
    <TextInput
      style={props.style}
      className={styles.input}
      allow="numeric"
      value={props.value}
      onNumericValueChange={props.onChange}
      onBlur={e => props.onBlur?.(Number(e.currentTarget.value))}
      onFocus={props.onFocus}
      maxLength={2}
      max={props.max}
      min={props.min}
      formatBlurredValue={props.pad ? value => (
        String(value).padStart(2, '0')
      ) : null}
      testId={props.testId}
      readOnly={props.readOnly}
    />
  )
}