import 'whatwg-fetch';
import { getAccessToken, getUserId, isSupportLogin } from '../utils/user';
import moment from 'moment';
import { AppType, appType } from '../AppType';
import ActionNotification from '../components/core/ActionNotification/ActionNotification';
import { sleep, truncate } from '../utils';
import { askRemoveSupportLock } from '../components/dev/environment_indicator/support_login';

const { location } = document;

export const isLocalhost = ['127.0.0.1', 'localhost'].includes(location.origin);
export const isAlpha = location.origin.indexOf('elipt.is') != -1;

let origin = location.origin;
if (isAlpha) {
  //  The schema may be resolved to http if embedded (such as during tests).
  origin = origin.replace('http://', 'https://');
}

export type Method = 'get' | 'post' | 'put' | 'delete' | 'head';

const urlMaxLength = 2047;

export class Api {
  getUrl(path: string, query = null, opts?: RequestOptions) {
    path = path || "";
    query = query || "";

    if (!path.startsWith("/")) {
      path = `/${path}`;
    }

    if (query && typeof query === "string" && !query.startsWith("?")) {
      query = `?${query}`;
    }

    if (query && typeof query !== "string") {
      //  convert obj to string
      let queryStr = ''
      let delimiter = '?'
      for (let key of Object.keys(query)) {
        if (query[key] == null) {
          continue;
        }

        let value = query[key];

        if (Array.isArray(value)) {
          value = value.join(',');

          if (!value.length) {
            continue;
          }
        } else if (value && typeof value === 'object' && !(value instanceof Date)) {
          value = JSON.stringify(value);
        }

        queryStr += `${delimiter}${key}=${encodeURIComponent(value)}`
        delimiter = '&'
      }

      query = queryStr;
    }

    const concatenatedUrl = () => `${urlWithoutQuery}${query}`;

    const urlWithoutQuery = `${this.urlBase}${path}`
    let url = concatenatedUrl();

    const truncateQuery = opts?.truncateQuery !== false && !opts?.overflowQueryToHeader;

    if (url.length > urlMaxLength && truncateQuery
      && urlWithoutQuery.length < urlMaxLength && url.length > urlMaxLength) {
      url = url.substr(0, urlMaxLength);
    }

    return url;
  }

  /**
   * Sends a GET request to this API.
   * @param path Relative path to request.
   * @param query Optional query object.
   * @param opts Request options.
   * @returns Response.
   */
  get<T = any>(path: string, query?: object | null, opts?: RequestOptions): Promise<T> {
    return this.request(path, 'get', null, query, opts);
  }

  /**
   * Sends a HEAD request to this API.
   * @param path Relative path to request.
   * @param query Optional query object.
   * @param opts Request options.
   * @returns Response.
   */
  head<T = any>(path: string, query?: object | null, opts?: RequestOptions): Promise<T> {
    return this.request(path, 'head', null, query, opts);
  }

  /**
   * Sends a PUT request to this API.
   * @param path Relative path to request.
   * @param body Request body.
   * @param query Optional query object.
   * @param opts Request options.
   * @returns Response.
   */
  put<T = any>(path: string, body?: object | null, query?: object | null, opts?: RequestOptions): Promise<T> {
    return this.request(path, 'put', body, query, opts);
  }

  /**
   * Sends a POST request to this API.
   * @param path Relative path to request.
   * @param body Request body.
   * @param query Optional query object.
   * @param opts Request options.
   * @returns Response.
   */
  post<T = any>(path: string, body?: object | null, query?: object | null, opts?: RequestOptions): Promise<T> {
    return this.request(path, 'post', body, query, opts);
  }

  /**
   * Sends a DELETE request to this API.
   * @param path Relative path to request.
   * @param query Optional query object.
   * @param opts Request options.
   * @returns Response.
   */
  del<T = any>(path: string, query?: object | null, opts?: RequestOptions): Promise<T> {
    return this.request(path, 'delete', null, query, opts);
  }

  private async request(path: string, method: Method = 'get', body = null, query = null, opts?: RequestOptions) {
    if (method !== 'get' && method !== 'head' && isSupportLogin()) {
      if (!opts?.ignoreSupportLogin && !askRemoveSupportLock()) {
        ActionNotification.show("Förhindrat ändring åt företag som kundservice");
        throw new Error("Cannot do non-GET request as support");
      }
    }

    const isFormData = body instanceof FormData;
    let url = this.getUrl(path, query, opts);

    if (body && typeof body == "object" && !isFormData) {
      body = JSON.stringify(timezonize(body));
    }

    const redirectOnUnauthorized = opts?.redirectOnUnauthorized !== false && (
      appType === AppType.Admin || appType === AppType.Main || appType === AppType.Test
    );

    const defaultHeaders = {};

    const accessToken = getAccessToken();
    //  APIs do not accept user token from cookies to better prevent CSRF attacks.
    if (accessToken) {
      defaultHeaders["authorization"] = `Bearer ${accessToken}`;
    }

    if (!isFormData) {
      defaultHeaders["content-type"] = "application/json";
    }

    const headers = {
      ...defaultHeaders,
      ...lowerCaseProperties(opts?.headers)
    };

    const [_, urlWithoutQuery, queryString] = /([^?]+)(\??.+)/.exec(url);
    if (opts?.overflowQueryToHeader && url.length > urlMaxLength && queryString.length) {
      url = urlWithoutQuery;
      headers['x-long-query'] = queryString;
    }

    let result: Response;
    for (let attempt = 1, attemptCount = (opts?.genericErrorReattemptCount ?? 2) + 1; attempt <= attemptCount; attempt++) {
      try {
        result = await fetch(url, {
          method,
          cache: 'default',
          signal: opts?.signal,
          headers,
          body
        });
        break;
      } catch (error) {
        if (isGenericNetworkError(error) && attempt < attemptCount) {
          //  Retry.
          await sleep(2500);
          continue;
        }

        throw error;
      }
    }

    if (redirectOnUnauthorized) {
      if (result.status === 401) {
        //  Unauthorized, reload
        if (opts?.redirectToLogin !== false) {
          if (window.location.pathname !== '/login') {
            window.location.href = '/login';
          }
        } else {
          window.location.reload();
        }
        return;
      }
    }

    if (/application\/json/ig.test(result.headers.get("Content-Type"))) {
      const jsonString = await result.text();
      let jsonObject: any = {};
      let ok = result.ok;

      try {
        jsonObject = JSON.parse(jsonString);

      } catch (error) {
        console.error("Response could not be parsed as JSON: %s", truncate(jsonString, 250));
        ok = false;
        jsonObject = null;
      }

      if (!ok) {
        let error = new Error();

        //  For debugging. Do not conflict with response body.
        (error as any)._requestUrl = url;
        (error as any)._pathname = document.location.pathname;
        (error as any)._response = jsonObject || jsonString;

        (error as any).status = result.status;
        (error as any).statusText = result.statusText;

        if (!jsonObject) {
          error.message = jsonString;

        } else if ("localizedError" in jsonObject) {
          error.message = jsonObject.localizedError;

        } else if ("error" in jsonObject) {
          error.message = jsonObject.error || jsonObject.message;

        } else {
          error.message = method + " request failed: " + result.statusText;
        }

        let restProps = {
          ...jsonObject,
        }
        delete restProps.message
        delete restProps.error
        for (let prop in restProps) {
          error[prop] = restProps[prop];
        }

        //  Temporary, remove with #538
        if (result.status === 403 && jsonObject.message?.includes("user is suspended") && jsonObject.message?.includes(getUserId()) && !document.location.href.includes('/login')) {
          document.location.href = '/login';
        }

        console.log("Throwing error", error)

        throw error;
      }

      return jsonObject;
    } else {
      if (!result.ok) {
        let error = new Error(method + " request failed: " + result.statusText);
        (error as any).status = result.status;
        throw error;
      }

      let text = await result.text();

      return {
        text,
        status: result.status
      }
    }
  }

  constructor(private urlBase: string) {

  }
}

/**
 * A set of available public APIs.
 * Use an API to make backend requests.
 */
export const apis = {
  calendar: new Api('/api/calendar'),
  contacts: new Api('/api/contacts'),
  dial: new Api('/api/dial'),
  documents: new Api('/api/documents'),
  offers: new Api('/api/offers'),
  orders: new Api('/api/orders'),
  products: new Api('/api/products'),
  users: new Api('/api/users'),
  events: new Api('/api/events'),
  integrations: new Api('/api/integrations'),
};

/**
 * Private APIs. Do not use in production as they will not be available.
 */
export const privateApis = {
  calendar: new Api('/privateapi/calendar'),
  contacts: new Api('/privateapi/contacts'),
  dial: new Api('/privateapi/dial'),
  offers: new Api('/privateapi/offers'),
  orders: new Api('/privateapi/orders'),
  products: new Api('/privateapi/products'),
  users: new Api('/privateapi/users'),
};

export type RequestOptions = {
  headers?: object;

  /**
   * @default false
   */
  ignoreSupportLogin?: boolean;

  /**
   * @default true
   */
  redirectToLogin?: boolean;

  /**
   * @default true
   */
  redirectOnUnauthorized?: boolean;

  /**
   * @default true
   */
  truncateQuery?: boolean;

  /**
   * Whether to move the query string to an `x-query` HTTP header (virtually limitless in length) in case the request URL
   * is too long, as a result of a long query string.
   * 
   * The API must handle an `x-long-query` HTTP header as a query string in addition to the
   * standard query.
   * 
   * @default false
   */
  overflowQueryToHeader?: boolean;

  signal?: AbortSignal;

  /**
   * How many request re-attempts if we get "Failed to fetch".
   * @default 2
   */
  genericErrorReattemptCount?: number;
}

/**
 * Converts date instances to ISO strings with timezone.
 */
function timezonize(source: any) {
  if (Array.isArray(source)) {
    return source.map(element => timezonize(element));
  }

  if (moment.isDate(source)) {
    return moment(source).toISOString(true);
  }

  if (source && typeof source == 'object' && !(source instanceof File) &&
    !(source instanceof URL)) {
    let clone = { ...source };

    for (let key in clone) {
      clone[key] = timezonize(clone[key]);
    }

    return clone;
  }

  return source;
}

function lowerCaseProperties(obj: object) {
  if (!obj) {
    return {};
  }

  const lowerCased: object = {};

  for (let key in obj) {
    lowerCased[key.toLowerCase()] = obj[key];
  }

  return lowerCased;
}

export function isGenericNetworkError(error: any) {
  return error?.message === "Failed to fetch" || // Chrome
    error?.message === "Load failed"; // Safari
}