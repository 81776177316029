import { createContext, useEffect } from "react";
import { getCurrentTheme, setTheme, Theme } from "../../utils/theme";

type Props = {
  theme: DeepPartial<Theme>;
  id: string;
}

/**
 * Allows overriding theme in parts of the app.
 */
export const CurrentThemeContextProvider: React.FC<Props> = ({ theme, id, children }) => {

  useEffect(() => {
    setTheme(new Theme({ ...getCurrentTheme()?.constructorArgs ?? {}, ...theme }), "#" + id);
  }, [JSON.stringify(theme)]);

  return (
    <CurrentThemeContext.Provider value={{ theme }}>
      <div id={id}>
        {children}
      </div>
    </CurrentThemeContext.Provider>
  );
}

export const CurrentThemeContext = createContext<{
  theme: DeepPartial<Theme>;
}>(null);